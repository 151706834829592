module.exports = {
    methods : {
        /**
         * Translate the given key.
         */
        __(key, replace) {
            let translation, translationNotFound = true

            try {
                translation = key.split('.').reduce((t, i) => t[i] || null, this.$page.props.translations[this.$page.props.locale].php)

                if (translation) {
                    translationNotFound = false
                }
            } catch (e) {
                translation = key
            }

            if (translationNotFound) {
                translation = this.$page.props.translations[this.$page.props.locale]['json'][key]
                              ? this.$page.props.translations[this.$page.props.locale]['json'][key]
                              : key
            }

            _.forEach(replace, (value, key) => {
                translation = translation.replace(':' + key, value)
            })

            return translation
        }
    },
}
