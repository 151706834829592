import route   from 'ziggy';
import {Ziggy} from '@/ziggy';

const Toast = Swal.mixin({
    toast             : true,
    position          : 'top-end',
    showConfirmButton : false,
    timer             : 3000,
    timerProgressBar  : true,
    didOpen           : (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

let mixins = {
    methods : {
        setting(type) {
            return this.$page.props.settings[type];
        },
        imageLink(url, type = 'default') {
            let tmp_url = this.$page.props.storage_link + url;
            if (this.imageExist(tmp_url)) {
                return this.$page.props.storage_link + url;
            } else {
                if (type === 'user') {
                    return this.$page.props.storage_link + this.setting('default_user_image');
                }
                return this.$page.props.storage_link + this.setting('default_image');
            }
        },
        str_limit(string, limit) {
            return string.slice(0, limit) + (string.length > limit ? "..." : "");
        },
        imageExist(url) {
            let http = new XMLHttpRequest();

            http.open('HEAD', url, false);
            http.send();

            return http.status !== 404;
        },
        isValidUrl : function (path) {
            let url;
            try {
                url = new URL(path);
            } catch (_) {
                return false;
            }
            return url.protocol === "http:" || url.protocol === "https:";
        },

        asset : function (path = "") {
            if (this.isValidUrl(path)) return path;
            try {
                return window.ASSET_PATH_PREFIX.trimRight('/') + '/' + path.trimLeft('/');
            } catch (error) {
                if (error instanceof TypeError) {
                    alert('Please set window.ASSET_PATH_PREFIX global variable');
                }
                throw error;  // re-throw the error unchanged
            }
        },

        url   : (path = "") => {
            try {
                return this.$page.props.app_url.trimRight('/') + '/' + path.trimLeft('/');
            } catch (error) {
                if (error instanceof TypeError) {
                    alert('Please set APP URL global variable');
                }
                throw error;  // re-throw the error unchanged
            }
        },
        route : (name, params, absolute, config = Ziggy) => route(name, params, absolute, config),

        showModalMessage(message, type = 'success') {
            Swal.fire(
                type.toUpperCase() + '!',
                message,
                type,
            )
        },

        showToastMessage(message, type = 'success') {
            Toast.fire({
                icon  : type,
                title : message
            })
        },

        showConfirmDialogue(title, text, type, confirmButtonText, callback) {
            Swal.fire({
                title              : title,
                text               : text,
                icon               : type,
                showCancelButton   : true,
                confirmButtonColor : '#3085d6',
                cancelButtonColor  : '#d33',
                confirmButtonText  : confirmButtonText
            }).then((result) => {
                if (result.isConfirmed) {
                    callback();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    event.preventDefault();
                    Swal.fire(
                        'Cancelled!',
                        'Your data is safe!',
                        'error'
                    );
                }
            })
        },

        toastr(message, type = 'success') {
            if (type === 'success') {
                toastr.success(message);
            } else {
                toastr.error(message);
            }
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        changeLoading(value) {
            if (value) {
                $('#preloader').show();
                $('#main-wrapper').removeClass('show');
            } else {
                $('#preloader').hide();
                $('#main-wrapper').addClass('show');
            }
        },

        frontChangeLoading(value) {
            if (value) {
                $(".ic-preloader-head").delay().fadeIn();
            } else {
                $(".ic-preloader-head").delay().fadeOut();
            }
        },
        can(permission) {
            return this.$page.props.auth.user.permissions.includes(permission);
        },
    }
};

export default mixins;
