require('./bootstrap');

import {createApp, h} from 'vue';

import {createInertiaApp, Link, Head} from '@inertiajs/inertia-vue3';
import {InertiaProgress}              from '@inertiajs/progress';
import mx                             from "./mixins/index";
import Select2                        from 'vue3-select2-component';
import Copyright                      from "@/Layouts/Front/Partials/Copyright";
import DateTime                       from "@/Layouts/Front/Partials/DateTime";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title   : (title) => `${title} - ${appName}`,
    resolve : (name) => import(`./Pages/${name}`),
    setup({el, app, props, plugin}) {
        return createApp({render : () => h(app, props)})
            .use(plugin)
            .mixin(mx)
            .mixin(require('./trans'))
            .component('inertia-link', Link)
            .component('head-component', Head)
            .component('Select2', Select2)
            .component('Copyright', Copyright)
            .component('DateTime', DateTime)
            .mount(el);
    },
});

InertiaProgress.init({color : '#4B5563'});

