<template>
    <p class="text-center font-weight-bold" :style="{color: '#1ab394', fontSize: '20px'}">
        <span>
            {{ (new Date()).getMonth() + ' ' + (new Date()).getDate() + ' ' + (new Date()).getFullYear() }}
        </span>
        <span>
             -
        </span>
        <span>
            9:30:13 PM
        </span>
    </p>
</template>

<script>
export default {
    name : "DateTime"
}
</script>
